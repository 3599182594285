import * as React from "react"
import { Link } from "gatsby"
import Video from "../components/video"
import { StaticImage } from "gatsby-plugin-image"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Media from 'react-bootstrap/Media'
import Button from 'react-bootstrap/Button'

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Vigilant Tutorials" description="Vigilant is a powerful 8-Channel Online Permanently Mounted Condition Monitoring Solution. Watch these three short tutorials detailing Vigilant’s easy to use desktop and workstation." />
    <Jumbotron fluid>
  <Container className="marginTop">
  <Media>
    <Media.Body>
    <StaticImage
      src="../images/vigilant-logo-large.png"
      width={400}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions - Vigilant Logo"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
    <h1>Vigilant Desktop & Workstation Tutorials</h1>
      <p className="leadText">
      Vigilant is an 8-Channel online permanently mounted condition monitoring solution. With Vigilant you can monitor your most critical assets using the versatility of ultrasound and the analytics of vibration, and then bring the conditional data to a common location to perform analysis. 
      </p>

      <p className="leadBody">
      The Vigilant system brings huge potential for improvements within your maintenance and reliability programs with its ability to promote cross-departmental collaboration and cooperation, which in turn drives innovation and productivity. Vigilant’s ability to provide data-driven insights and real-time reporting should also aid in business decision-making.
      </p>

      <p className="leadBody">
      Vigilant’s powerful embedded trending and analysis software is accessed via a standard web browser, and additionally has the option to bidirectionally communicate via OPC or Modbus TCP. The Vigilant System can be connected to a network via WIFI, ethernet or cellular data. Anyone with network credentials can access critical asset health data… anywhere. Vigilant is outfitted with all the industry standard analysis tools. Within the Vigilant System you have the ability to customize your Vigilant Dashboard and workstation to your liking.
      </p>

      <p className="leadBody">
      Below are three short tutorials detailing designed to familiarize you with the Vigilant System.
      </p>

      <p className="leadBody">
      To learn more about Vigilant and its Embedded Trending and Analysis Software watch our Episode of Ultrasound… Beyond the Essentials™ on SDT.Training – A Vigilant Approach to Reliability where Rob Dent CMRP, CRL, Sales Manager for SDT Ultrasound Solutions presents a live demonstration of the new Vigilant System.
      </p>
      <Button href="https://sdt.training/a-vigilant-approach-to-reliability/">Watch Episode</Button>
      <p className="leadText">Scroll down for tutorials</p>
    </Media.Body>
  </Media>
  </Container>
</Jumbotron>

<Container className="marginTop">
      <h2>Vigilant Embedded Trending and Analysis Software Tutorial #1</h2>
      <hr />
      <p className="leadBody">
      In this short tutorial Robert Dent teaches the basics of configuring your Vigilant Desktop Layout.  
      </p>

      <p className="leadBody">
      First, this tutorial covers a fast and easy method for creating and organizing a new dashboard/workstation using Vigilant's online embedded software. The software includes an easy to use window pane organizational scheme - Robert demonstrates best practices for keeping your workspace organized.
      </p>

      
      <iframe src="https://player.vimeo.com/video/545547546?h=19be7e1bbb" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

<p className="leadBody">
      Next Rob covers how to assign widgets and other tools to each windowpane created. 
The final thing covered in the first tutorial is a method for configuring Waterfall Plots and vibration spectrums to your liking.
      </p>

  </Container>

  <Container className="marginTop">
      <h2>Vigilant Embedded Trending and Analysis Software Tutorial #2</h2>
      <hr />
      <p className="leadBody">
      In Vigilant System Tutorial #2 Rob Dent walks through creating new machines and assigning it to one of the 8-Channels within the Vigilant System.
      </p>

      <p className="leadBody">
      After completing the second tutorial, viewers should be able to create a new machine within their vigilant system and assign it to one of the panes created within their workspace, and then create data points on the machine to be assigned to one of Vigilant’s 8 Channels. 
      </p>

      
      <iframe src="https://player.vimeo.com/video/548292962?h=44ddd4baa2" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

  </Container>

  <Container className="marginTop">
      <h2>Vigilant Embedded Trending and Analysis Software Tutorial #3</h2>
      <hr />
      <p className="leadBody">
      The third Vigilant Embedded Trending and Analysus Software Tutorial is all about setting processing modes and data analysis.
      </p>

      <p className="leadBody">
      After completing this tutorial, you should have an understanding of how to add processing modes to the data collection points added in the previous tutorial, be able to define measurement types on each of their data collection points, and set up parameters on your timewave form. 
      </p>

      
      <iframe src="https://player.vimeo.com/video/552126025?h=87b74bbaf4" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        <hr />
  </Container>

  <Container className="marginTop">
  <Media>
    <Media.Body>
      <p className="leadBody">
      SDT’s Permanent Monitoring solutions contribute to the reliability of your most critical assets while improving overall efficiency of your operations.
      </p>

      <p className="leadBody">
      Real-time Ultrasound and Vibration data indicate changes in healthy function earlier in the P-F Curve than other condition monitoring technologies. Advanced warning allows every maintenance job to be planned while reducing levels of critical spares held in inventory. Reliability teams benefit from a proactive culture that affords them time to eliminate defects rather than simply applying band-aids.
      </p>
      <Link className="btn btn-primary" to="/">Home</Link>&nbsp;&nbsp;<Button href="http://ftp.sdt.be/pub/Products/VIGILANT/Brochure/Vigilant_Brochure_EN.pdf">Download Brochure</Button> 
    </Media.Body>
    <StaticImage
      src="../images/vigilant-2.jpg"
      width={400}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions Vigilant"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
  </Media>
  </Container>
  
  </Layout>
)

export default IndexPage
